/*--------------------------------------------------------------
 TABLE OF CONTENTS:
----------------------------------------------------------------
# Vars

# Blog
# Single Post Navigation
# Blog - Templates

--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Vars
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.ish-blog-masonry,
.ish-blog-classic
{
	img
	{
		width: 100%;
		max-width: 100%;
	}

	.ish-item
	{
		display: table;
		width: 100%;
		//padding-bottom: 50px;

		.ish-blog-post-title
		{
			> *:first-child
			{
				margin-top: 50px;
			}

			a
			{
				text-decoration: none;
			}
		}

		.ish-row
		{
			padding-bottom: 50px;
		}

		.ish-blog-post-details
		{
			margin-bottom: 50px;

			.ish-label
			{
				opacity: 0.5;
			}

			.ish-read-more
			{
				margin-top: 14px;
			}

			a
			{
				text-decoration: none;
			}
		}

		.ish-blog-post-content
		{
			p:first-child
			{
				margin-top: 0;
			}
		}
	}
}

.ish-blog-classic
{
	.ish-item
	{
		padding-bottom: 50px;

		&:last-child
		{
			padding-bottom: 30px;
		}
	}
}

/*--------------------------------------------------------------
# Single Post Navigation
--------------------------------------------------------------*/
.ish-post-navigation
{

	.ish-separator
	{
		opacity: 0.5;
	}

	.nav-links
	{
		text-align: center;

		@include clearfix;

		div
		{
			display: inline;
		}
	}

	a
	{
		text-decoration: none;
		position: relative;

		span
		{
			display: none;

		}

	}
}

/*--------------------------------------------------------------
# Blog - Templates
--------------------------------------------------------------*/
.page-template-blog,
.page-template-blog-detail
{
	/*.site-content
	{
		margin-top: -80px;
	}*/
}
